<template>
  <main class="page container-padding">
    <pre class="delete-profile__description">
      Зеленое яблоко
Политика удаления аккаунта и связанных данных

Мы,Зеленое яблоко, стремимся обеспечить прозрачность и контроль в отношении ваших личных данных. В этом документе описан процесс и инструкция по запросу удаления вашего аккаунта и связанных с ним данных. Пожалуйста, внимательно прочитайте следующую информацию.

1. Упоминание названия приложения или имени разработчика

Наше приложение называется Зеленое яблоко и разработано comrades.dev. Мы предоставляем вам возможность удалить свой аккаунт и все связанные с ним данные в соответствии с нашей политикой удаления аккаунта.

2. Пошаговая инструкция по запросу удаления аккаунта

Наши пользователи могут запросить удаление своего аккаунта и связанных с ним данных, следуя этой простой пошаговой инструкции:

Шаг 1: Зайдите в настройки вашего аккаунта в приложении Зеленое яблоко.
Шаг 2: Найдите раздел "Управление аккаунтом" или подобный раздел, который содержит опции для управления вашим аккаунтом.
Шаг 3: В этом разделе вы должны найти опцию "Удалить аккаунт" или подобную опцию.
Шаг 4: При выборе опции "Удалить аккаунт" система может запросить ваше подтверждение удаления. Введите необходимую информацию или выполните дополнительные шаги, если они требуются.
Шаг 5: После подтверждения удаления аккаунта все связанные с ним данные будут удалены в соответствии с нашей политикой удаления аккаунта.

3. Удаление данных и сроки хранения

После запроса удаления аккаунта будут удалены следующие типы данных, связанные с вашим аккаунтом:
- Личная информация, такая как имя, электронная почта, номер телефона и адрес.
- Все загруженные вами контенты, включая фотографии, видео или документы.
- История активности, включая ваши действия, посты, комментарии и сообщения.

Сроки хранения данных после удаления аккаунта:
- Ваши данные будут удалены немедленно или в течение 30 дней] с момента запроса удаления аккаунта. Однако, некоторые данные могут быть сохранены в анонимной форме в целях статистического анализа или соблюдения юридических требований.

Обратите внимание, что после удаления аккаунта вы больше не сможете восстановить доступ к вашему аккаунту или связанным с ним данным.

Мы стремимся обеспечить безопасность и конфиденциальность ваших данных. Если у вас возникли вопросы или проблемы в отношении удаления аккаунта, пожалуйста, обратитесь к нашей службе поддержки пользователей для получения дополнительной помощи.

С уважением,

Зеленое яблоко

    </pre>
  </main>
</template>

<script>
export default {
  name: "DeleteProfileInfo",
};
</script>

<style lang="stylus">
.delete-profile__description {
  white-space pre-line
}
</style>
